// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-actuaciones-js": () => import("./../../../src/pages/actuaciones.js" /* webpackChunkName: "component---src-pages-actuaciones-js" */),
  "component---src-pages-clases-js": () => import("./../../../src/pages/clases.js" /* webpackChunkName: "component---src-pages-clases-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-discografia-js": () => import("./../../../src/pages/discografia.js" /* webpackChunkName: "component---src-pages-discografia-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-galleria-js": () => import("./../../../src/pages/galleria.js" /* webpackChunkName: "component---src-pages-galleria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

